// bolao.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map} from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BolaoService {
  constructor(private http: HttpClient) { }

  getRodadas(filtros?: any): Observable<any> {
    const params = this.buildParams(filtros);
    return this.http.get<any>(
      environment.API_URL.concat(`bolao/rodadas${params ? '?' + params : ''}`)
    ).pipe(
      catchError(error => {
        console.error('Erro ao obter rodadas:', error);
        return of({ rodadas: [] });
      })
    );
  }

  getRodada(id: number): Observable<any> {
    return this.http.get<any>(
      environment.API_URL.concat(`bolao/rodadas/${id}`)
    ).pipe(
      catchError(error => {
        console.error(`Erro ao obter rodada ${id}:`, error);
        return of({ rodada: null });
      })
    );
  }

  getJogos(rodadaId?: number): Observable<any> {
    const params = rodadaId ? `?rodada_id=${rodadaId}` : '';
    return this.http.get<any>(
      environment.API_URL.concat(`bolao/jogos${params}`)
    ).pipe(
      catchError(error => {
        console.error('Erro ao obter jogos:', error);
        return of({ jogos: [] });
      })
    );
  }

  registrarApostas(userId: number, apostas: any[], descontarMoedas: boolean = false) {
    const payload = {
      participante_id: userId,
      apostas: apostas,
      descontar_moedas: descontarMoedas
    };
    
    return this.http.post<any>(
      environment.API_URL.concat('bolao/apostas'),
      payload
    );
  }

  getApostasUsuario(rodada_id: number): Observable<any> {
    const user_id = localStorage.getItem('userId');
    
    return this.http.get<any>(
      environment.API_URL.concat(`bolao/apostas_usuario_por_rodada?rodada_id=${rodada_id}&participante_id=${user_id}`)
    ).pipe(
      catchError(error => {
        console.error('Erro ao obter apostas do usuário:', error);
        return of({ status: 'success', data: [] });
      })
    );
  }

  obterPosicaoRanking(userId: number) {
    return this.http.get<any>(
      environment.API_URL.concat(`bolao/ranking?participante_id=${userId}`)
    ).pipe(
      map(response => {
        if (response && response.status === 'success' && response.ranking) {
          const userRanking = response.ranking.find(item => item.participante_id == userId);
          return { posicao: userRanking ? userRanking.posicao : null };
        }
        return { posicao: null };
      }),
      catchError(error => {
        console.error('Erro ao obter posição no ranking:', error);
        return of({ posicao: null });
      })
    );
  }

  getRanking(tipo: 'rodada' | 'mensal' | 'geral', params?: any): Observable<any> {
    let url = environment.API_URL.concat('bolao/ranking');
    
    const queryParams = [];
    if (tipo === 'rodada' && params?.rodadaId) {
      queryParams.push(`rodada_id=${params.rodadaId}`);
    } else if (tipo === 'mensal' && params?.mes && params?.ano) {
      queryParams.push(`mes=${params.mes}&ano=${params.ano}`);
    }
    
    if (queryParams.length > 0) {
      url += '?' + queryParams.join('&');
    }
    
    return this.http.get<any>(url).pipe(
      catchError(error => {
        console.error('Erro ao obter ranking:', error);
        return of({ ranking: [] });
      })
    );
  }

  getPremios(): Observable<any> {
    return this.http.get<any>(
      environment.API_URL.concat('bolao/premios')
    ).pipe(
      catchError(error => {
        console.error('Erro ao obter prêmios:', error);
        return of({ data: {} });
      })
    );
  }

  private buildParams(params: any): string {
    if (!params) return '';
    
    return Object.keys(params)
      .filter(key => params[key] !== undefined && params[key] !== null)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&');
  }


}