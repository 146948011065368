import { Component, OnInit } from '@angular/core';
import { BolaoService } from '../services/bolao.service';
import { LoadingController } from '@ionic/angular';

@Component({
  selector: 'app-premios',
  templateUrl: './premios.component.html',
  styleUrls: ['./premios.component.scss'],
})
export class PremiosComponent implements OnInit {
  categorias: string[] = ['rodada', 'mensal', 'turno', 'brasileirao'];
  categoriaSelecionada: string = 'rodada';
  premios: any = {};
  carregando: boolean = false;
  
  constructor(
    private bolaoService: BolaoService,
    private loadingController: LoadingController
  ) { }

  ngOnInit() {
    this.carregarPremios();
  }

  async carregarPremios() {
    const loading = await this.loadingController.create({
      message: 'Carregando prêmios...',
    });
    await loading.present();
    this.carregando = true;

    this.bolaoService.getPremios().subscribe(
      (res) => {
        this.premios = res.data || {};
        loading.dismiss();
        this.carregando = false;
      },
      (err) => {
        console.error('Erro ao carregar prêmios', err);
        loading.dismiss();
        this.carregando = false;
      }
    );
  }

  selecionarCategoria(categoria: string) {
    this.categoriaSelecionada = categoria;
  }

  getIcone(categoria: string): string {
    switch (categoria) {
      case 'rodada':
        return 'calendar-outline';
      case 'mensal':
        return 'calendar-number-outline';
      case 'turno':
        return 'flag-outline';
      case 'brasileirao':
        return 'trophy-outline';
      default:
        return 'gift-outline';
    }
  }

  getTitulo(categoria: string): string {
    switch (categoria) {
      case 'rodada':
        return 'Prêmios por Rodada';
      case 'mensal':
        return 'Prêmios Mensais';
      case 'turno':
        return 'Prêmios por Turno';
      case 'brasileirao':
        return 'Prêmios do Campeonato';
      default:
        return 'Prêmios';
    }
  }

  getCorCategoria(categoria: string): string {
    switch (categoria) {
      case 'rodada':
        return 'primary';
      case 'mensal':
        return 'success';
      case 'turno':
        return 'warning';
      case 'brasileirao':
        return 'danger';
      default:
        return 'medium';
    }
  }
}