import { Component, Injectable, OnInit, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Login } from '../../../../app/classes/login';
import { AuthService } from '../../../../app/services/auth.service';
import { environment } from '../../../../environments/environment';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Location } from '@angular/common';
import { StorageService } from '../../../../app/services/storage.service';
declare var google: any;

@Component({
  selector: 'login-component',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

@Injectable({
  providedIn: 'root'
})
export class LoginComponent implements OnInit {

  mostrarSenha = false;
  public eye = faEye;
  public user: Login = new Login();
  public loggedIn: boolean;
  public mode: string = 'login';
  public msg: string = '';
  public carregando = false;
  public googleIcon = 'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/52569925-0cb2-4288-9970-1274a39d9a71/76426147-347a-4823-901c-0902c17234a7?org_if_sml=1&force_format=original&w=200';
  public regulamento: boolean = false;
  
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private loader: NgxUiLoaderService,
    private modalService: NgbModal,
    private location: Location,
    private ngZone: NgZone,
    private storageService: StorageService // Encapsulando o uso de localStorage
  ) {}

  ngOnInit() {
    this.initGoogleLogin();

    const savedDocumento = this.storageService.getItem('documento');
    if (savedDocumento) {
      this.user.documento = savedDocumento;
    }

    const savedToken = this.storageService.getItem('token');
    if (savedToken) {
      this.router.navigate(['/home']).then(() => { location.reload(); });
    }

    this.loader.stop();
  }

  initGoogleLogin() {
    if (typeof google !== 'undefined') {
      google.accounts.id.initialize({
        client_id: "176648358869-9uti8bjdaegl2obcm7g04d04iuiol7j7.apps.googleusercontent.com",
        callback: this.handleCredentialResponse.bind(this),
        auto_select: false,
        cancel_on_tap_outside: true,
      });

      google.accounts.id.renderButton(
        document.getElementById("google-button"),
        { theme: "outline", size: "large", width: "100%" }
      );

      google.accounts.id.prompt();
    } else {
      console.error('Google library not loaded');
    }
  }

  async handleCredentialResponse(response: any) {
    if (response.credential) {
      this.ngZone.run(() => {
        const decodedToken = this.decodeJWTToken(response.credential);
        sessionStorage.setItem("loggedInUser", JSON.stringify(decodedToken));

        const { email, name, picture } = decodedToken;

        this.http.post<any>(environment.API_URL.concat('cadastro/obter_email/'), { email })
          .subscribe((data: any) => {
            if (data.status === 1) {
              this.saveUserData(data.user, picture);
              this.router.navigate(['/home']).then(() => { location.reload(); });
            } else {
              this.router.navigate(['/cadastro'], { queryParams: { email, nome: name } });
            }
          });
      });
    } else {
      console.log("Error: " + response.error);
    }
  }

  decodeJWTToken(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(c =>
      '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));

    return JSON.parse(jsonPayload);
  }

  togglePasswordVisibility() {
    this.mostrarSenha = !this.mostrarSenha;
  }

  login() {
    this.carregando = true;

    if (!this.user.documento) {
      this.msg = 'Preencha o campo CPF';
      this.carregando = false;
      return;
    }

    this.loader.start();

    this.auth.login(this.user).subscribe(
      (data: any) => {
        if (data.nome) {
          this.saveUserData(data, data.foto);
          this.router.navigate(['/home']).then(() => { location.reload(); });
        } else if (data.texto) {
          this.msg = data.texto;
          this.loader.stop();
        }
      },
      (error) => {
        console.error('Erro no login:', error);
        this.loader.stop();
      }
    );
  }

  saveUserData(user: any, foto: string) {
    const nomeArray = user.nome.split(' ');
    let nomeView = nomeArray.length > 2 ? `${nomeArray[0]} ${nomeArray[1]}` : user.nome;
    
    this.storageService.setItem('id_confirmacao', user.id);
    this.storageService.setItem('userName', nomeView);
    this.storageService.setItem('userId', user.id);
    this.storageService.setItem('token', user.token);
    this.storageService.setItem('tipo', user.tipo);

    if (user.bloqueado) {
      this.storageService.setItem('bloqueado', 'sim');
    }

    if (foto) {
      const fotoSrc = environment.API_URL.replace('/api/', '/static/participantes/' + foto);
      this.storageService.setItem('foto_src', fotoSrc);
    } else {
      this.storageService.removeItem('foto_src');
    }
  }

  esqueci_senha() {
    this.loader.start();
    this.auth.recuperar_senha(this.user.documento).subscribe(data => {
      this.msg = data.mensagem;
      this.loader.stop();
    });
  }

  abrir_regulamento() {
    this.regulamento = true;
  }
}
