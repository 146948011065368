import { Component, OnInit } from '@angular/core';
import { BolaoService } from '../services/bolao.service';
import { LoadingController } from '@ionic/angular';

@Component({
  selector: 'app-resultados',
  templateUrl: './resultados.component.html',
  styleUrls: ['./resultados.component.scss'],
})
export class ResultadosComponent implements OnInit {
  rodadas: any[] = [];
  rodadaSelecionada: any;
  jogos: any[] = [];
  apostasUsuario: any[] = [];
  participanteId = 1; // Deve vir do serviço de autenticação
  pontuacaoTotal = 0;
  
  constructor(
    private bolaoService: BolaoService,
    private loadingController: LoadingController
  ) { }

  ngOnInit() {
    this.carregarRodadas();
  }

  async carregarRodadas() {
    const loading = await this.loadingController.create({
      message: 'Carregando rodadas...',
    });
    await loading.present();

    this.bolaoService.getRodadas({ status: 'encerrada' }).subscribe(
      (res) => {
        this.rodadas = res.rodadas;
        if (this.rodadas.length > 0) {
          this.selecionarRodada(this.rodadas[0].id);
        }
        loading.dismiss();
      },
      (err) => {
        console.error('Erro ao carregar rodadas', err);
        loading.dismiss();
      }
    );
  }

  selecionarRodada(rodadaId: number) {
    this.rodadaSelecionada = rodadaId;
    this.carregarJogos(rodadaId);
  }

  async carregarJogos(rodadaId: number) {
    const loading = await this.loadingController.create({
      message: 'Carregando resultados...',
    });
    await loading.present();

    // Aqui você precisaria criar um endpoint específico que traga os jogos e suas apostas
    // Esta é uma simplificação
    this.bolaoService.getJogos(rodadaId).subscribe(
      (res) => {
        this.jogos = res.jogos;
        
        // Simula a obtenção das apostas do usuário
        this.apostasUsuario = []; // Deveria ser preenchido com as apostas reais
        this.pontuacaoTotal = 0; // Deveria ser calculado com base nos acertos
        
        loading.dismiss();
      },
      (err) => {
        console.error('Erro ao carregar jogos', err);
        loading.dismiss();
      }
    );
  }

  getCorResultado(resultado: string) {
    switch (resultado) {
      case 'mandante': return 'danger';
      case 'empate': return 'warning';
      case 'visitante': return 'primary';
      default: return 'medium';
    }
  }
}