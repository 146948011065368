import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'resultadoTexto'
})
export class ResultadoTextoPipe implements PipeTransform {
  transform(resultado: string): string {
    switch (resultado) {
      case 'mandante':
        return 'Vitória Mandante';
      case 'empate':
        return 'Empate';
      case 'visitante':
        return 'Vitória Visitante';
      case 'pendente':
        return 'Pendente';
      default:
        return resultado;
    }
  }
}