import { Component, OnInit } from '@angular/core';
import { BolaoService } from '../services/bolao.service';
import { AlertController, LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-apostas',
  templateUrl: './apostas.component.html',
  styleUrls: ['./apostas.component.scss'],
})
export class ApostasComponent implements OnInit {
  rodadas: any[] = [];
  jogos: any[] = [];
  rodadaSelecionada: any;
  apostas: any = {};
  user_id: any; 
  userName: string = '';
  loadedData: boolean = false;
  apostasRealizadas: boolean = false;
  user_name: any;
  rodadasEncerradas: any[] = [];
  mostrarModalConfirmacao: boolean = false;
  posicaoRanking: number = null;

  mostrarModalPremios: boolean = false;
  mostrarModalRegras: boolean = false;

  moedas: number = 0;



  constructor(
    private bolaoService: BolaoService,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private router: Router,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');
    

    if (!this.user_id) {
      this.router.navigate(['/login']);
      return;
    }

    this.carregarRodadas();
    this.carregarRodadasEncerradas();
    this.carregarPosicaoRanking();
    this.carregarSaldoMoedas(); 
  }

  async carregarRodadas() {
    const loading = await this.loadingController.create({
      message: 'Carregando rodada...',
    });
    await loading.present();
    
    this.bolaoService.getRodadas({ status: 'aberta' }).subscribe(
      (res) => {
        this.rodadas = res.rodadas || [];
        
        loading.dismiss();
        
        if (this.rodadas.length > 0) {
          this.rodadaSelecionada = this.rodadas[0];
          this.carregarJogos(this.rodadaSelecionada.id);
        } else {
          this.mostrarAlerta('Informação', 'Não há rodadas abertas para apostas no momento.');
        }
      },
      (err) => {
        console.error('Erro ao carregar rodadas', err);
        loading.dismiss();
        this.mostrarAlerta('Erro', 'Não foi possível carregar as rodadas. Por favor, tente novamente mais tarde.');
      }
    );
  }

  async carregarRodadasEncerradas() {
    const loading = await this.loadingController.create({
      message: 'Carregando histórico...',
    });
    await loading.present();
    
    this.bolaoService.getRodadas({ status: 'encerrada' }).subscribe(
      (res) => {
        this.rodadasEncerradas = res.rodadas || [];
        
        // Inicializa a propriedade expanded para controlar a visualização
        this.rodadasEncerradas.forEach(rodada => {
          rodada.expanded = false;
          rodada.jogos = []; // Inicializa array de jogos
        });
        
        loading.dismiss();
      },
      (err) => {
        console.error('Erro ao carregar rodadas encerradas', err);
        loading.dismiss();
      }
    );
  }

  carregarSaldoMoedas() {
    this.http.get<any>(environment.API_URL.concat('pontos/index?id_participante=' + this.user_id)).subscribe(
      (data) => {
        this.moedas = parseInt(data.moedas);
      },
      (error) => {
        console.error('Erro ao carregar saldo de moedas:', error);
      }
    );
  }

  carregarPosicaoRanking() {
    if (this.user_id) {
      this.bolaoService.obterPosicaoRanking(parseInt(this.user_id)).subscribe(
        (result) => {
          this.posicaoRanking = result.posicao;
        }
      );
    }
  }

  toggleRodada(rodada: any) {
    rodada.expanded = !rodada.expanded;
    
    if (rodada.expanded && (!rodada.jogos || rodada.jogos.length === 0)) {
      this.carregarJogosRodadaEncerrada(rodada);
    }
  }

  abrirModalPremios() {
    this.mostrarModalPremios = true;
  }
  fecharModalPremios() {
    this.mostrarModalPremios = false;
  }

  abrirModalRegras() {
    this.mostrarModalRegras = true;
  }
  fecharModalRegras() { 
    this.mostrarModalRegras = false;
  }

  async carregarJogosRodadaEncerrada(rodada: any) {
    const loading = await this.loadingController.create({
      message: 'Carregando jogos...',
    });
    await loading.present();
    
    this.bolaoService.getJogos(rodada.id).subscribe(
      (res) => {
        rodada.jogos = res.jogos || [];
        
        // Carrega as apostas do usuário para esta rodada
        this.carregarApostasUsuarioRodadaEncerrada(rodada);
        
        loading.dismiss();
      },
      (err) => {
        console.error('Erro ao carregar jogos da rodada encerrada', err);
        loading.dismiss();
      }
    );
  }

  carregarApostasUsuarioRodadaEncerrada(rodada: any) {
    console.log(`Carregando apostas do usuário para rodada ${rodada.id}`);
    
    this.bolaoService.getApostasUsuario(rodada.id).subscribe(
      (res) => {
        console.log('Resposta API apostas para rodada encerrada:', res);
        
        if (res && res.data && Array.isArray(res.data) && res.data.length > 0) {
          // Mapeia as apostas do usuário para os jogos
          const apostasMap = {};
          res.data.forEach((aposta: any) => {
            console.log(`Aposta para jogo ${aposta.jogo_id}: ${aposta.aposta}`);
            apostasMap[aposta.jogo_id] = aposta.aposta;
          });
          
          console.log('Mapa de apostas criado:', apostasMap);
          
          // Adiciona a informação da aposta do usuário a cada jogo
          rodada.jogos.forEach(jogo => {
            const apostaUsuario = apostasMap[jogo.id] || null;
            console.log(`Atribuindo aposta ao jogo ${jogo.id}: ${apostaUsuario}`);
            jogo.aposta_usuario = apostaUsuario;
          });
          
          console.log('Jogos com apostas atribuídas:', rodada.jogos);
        } else {
          console.log('Nenhuma aposta encontrada para esta rodada');
        }
      },
      (err) => {
        console.error('Erro ao carregar apostas do usuário para rodada encerrada', err);
      }
    );
  }
  


  selecionarRodada(rodada: any) {
    this.rodadaSelecionada = rodada;
    this.carregarJogos(rodada.id);
  }

  isRodadaFechada(): boolean {
    if (!this.rodadaSelecionada || !this.rodadaSelecionada.data_fechamento) {
      return false;
    }
    
    const dataFechamento = new Date(this.rodadaSelecionada.data_fechamento);
    const dataAtual = new Date();
    
    return dataAtual > dataFechamento;
  }

  async carregarJogos(rodadaId: number) {
    const loading = await this.loadingController.create({
      message: 'Carregando jogos...',
    });
    await loading.present();
    
    this.bolaoService.getJogos(rodadaId).subscribe(
      (res) => {
        this.jogos = res.jogos || [];
        
        // Carregar as apostas já feitas pelo usuário para esta rodada
        this.carregarApostasUsuario(rodadaId);
        
        loading.dismiss();
        
        if (this.jogos.length === 0) {
          this.mostrarAlerta('Informação', 'Não há jogos disponíveis para esta rodada.');
        }
      },
      (err) => {
        console.error('Erro ao carregar jogos', err);
        loading.dismiss();
        this.mostrarAlerta('Erro', 'Não foi possível carregar os jogos. Por favor, tente novamente mais tarde.');
      }
    );
  }

  carregarApostasUsuario(rodadaId: number) {
    this.apostasRealizadas = false;
    this.apostas = {};
    
    this.bolaoService.getApostasUsuario(rodadaId).subscribe(
      (res) => {
        console.log('Resposta da API de apostas:', res);
        
        if (res && res.data && Array.isArray(res.data) && res.data.length > 0) {
          this.apostasRealizadas = true;
          
          res.data.forEach((aposta: any) => {
            this.apostas[aposta.jogo_id] = aposta.aposta;
          });
        } else {
          this.apostasRealizadas = false;
        }
      },
      (err) => {
        console.error('Erro ao carregar apostas do usuário:', err);
        this.apostasRealizadas = false;
      }
    );
  }

  temApostas() {
    return this.apostas && Object.keys(this.apostas).length > 0;
  }

  todosJogosApostados(): boolean {
    if (!this.jogos || this.jogos.length === 0) {
      return true;
    }

    return this.jogos.every(jogo => this.apostas[jogo.id] !== undefined);
  }

  fecharModal() {
    this.mostrarModalConfirmacao = false;
  }

  selecionarAposta(jogoId: number, resultado: string) {
    if (this.isRodadaFechada()) {
      this.mostrarAlerta('Rodada Fechada', 'As apostas para esta rodada foram encerradas.');
      return;
    }
  
    this.apostas[jogoId] = resultado;
  }

  estaApostaSelecionada(jogoId: number, resultado: string): boolean {
    return this.apostas[jogoId] === resultado;
  }


  async enviarApostas() {
    if (!this.temApostas()) {
      this.mostrarAlerta('Atenção', 'Faça pelo menos uma aposta antes de confirmar.');
      return;
    }
    
    if (!this.todosJogosApostados()) {
      this.mostrarAlerta('Atenção', 'Você precisa fazer apostas em TODOS os jogos desta rodada antes de confirmar.');
      return;
    }

    if (this.moedas < 10) {
      this.mostrarAlerta('Saldo Insuficiente', 'Você precisa de 10 moedas para confirmar suas apostas. Seu saldo atual é de ' + this.moedas + ' moedas.');
      return;
    }
    
    // Em vez de mostrar o alert padrão, mostramos nosso modal personalizado
    this.mostrarModalConfirmacao = true;
  }


  async confirmarApostas() {
    if (!this.user_id) {
      this.mostrarAlerta('Erro', 'Você precisa estar logado para fazer apostas.');
      this.router.navigate(['/login']);
      return;
    }
    
    const loading = await this.loadingController.create({
      message: 'Enviando apostas...',
    });
    await loading.present();
    
    const apostasArray = Object.keys(this.apostas).map(jogoId => ({
      jogo_id: parseInt(jogoId),
      aposta: this.apostas[jogoId]
    }));
    
    this.bolaoService.registrarApostas(this.user_id, apostasArray, true).subscribe(
      (res) => {
        loading.dismiss();
        this.fecharModal(); 

        this.moedas -= 10;

        this.mostrarAlerta('Sucesso', 'Suas apostas foram registradas com sucesso!');
        this.carregarJogos(this.rodadaSelecionada.id); // Recarrega os jogos e apostas
      },
      (err) => {
        this.fecharModal(); 
        console.error('Erro ao enviar apostas', err);
        loading.dismiss();
        
        let mensagemErro = 'Não foi possível registrar suas apostas.';
        if (err.error && err.error.message) {
          mensagemErro = err.error.message;
        }
        
        this.mostrarAlerta('Erro', mensagemErro);
      }
    );
  }

  async mostrarAlerta(header: string, message: string) {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: ['OK']
    });
    await alert.present();
  }
}