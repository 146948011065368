import { Component, OnInit } from '@angular/core';
import { BolaoService } from '../services/bolao.service';
import { LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.scss'],
})
export class RankingComponent implements OnInit {
  tipoRanking: 'rodada' | 'mensal' | 'geral' = 'rodada';
  rodadas: any[] = [];
  rodadaSelecionada: any;
  meses = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  mesSelecionado = new Date().getMonth() + 1;
  anoSelecionado = new Date().getFullYear();
  
  ranking: any[] = [];
  posicaoUsuario: number = 0;
  carregando = false;
  
  constructor(
    private bolaoService: BolaoService,
    private loadingController: LoadingController,
    private router: Router,
  ) { }

  ngOnInit() {
    this.carregarRodadas();
  }

  async carregarRodadas() {
    this.carregando = true;
    
    this.bolaoService.getRodadas().subscribe(
      (res) => {
        this.rodadas = res.rodadas;
        if (this.rodadas.length > 0) {
          this.rodadaSelecionada = this.rodadas[0].id;
          this.carregarRanking();
        }
        this.carregando = false;
      },
      (err) => {
        console.error('Erro ao carregar rodadas', err);
        this.carregando = false;
      }
    );
  }

  async carregarRanking() {
    const loading = await this.loadingController.create({
      message: 'Carregando ranking...',
    });
    await loading.present();

    let params = {};
    
    if (this.tipoRanking === 'rodada') {
      params = { rodadaId: this.rodadaSelecionada };
    } else if (this.tipoRanking === 'mensal') {
      params = { mes: this.mesSelecionado, ano: this.anoSelecionado };
    }

    this.bolaoService.getRanking(this.tipoRanking, params).subscribe(
      (res) => {
        this.ranking = res.ranking;
        
        // Simulando encontrar a posição do usuário atual (id = 1)
        const usuarioAtual = this.ranking.find(u => u.participante_id === 1);
        this.posicaoUsuario = usuarioAtual ? usuarioAtual.posicao : 0;
        
        loading.dismiss();
      },
      (err) => {
        console.error('Erro ao carregar ranking', err);
        loading.dismiss();
      }
    );
  }

  alterarTipoRanking(evento: any) {
    this.tipoRanking = evento.detail.value;
    this.carregarRanking();
  }

  alterarRodada(evento: any) {
    this.rodadaSelecionada = evento.detail.value;
    this.carregarRanking();
  }

  alterarMes(evento: any) {
    this.mesSelecionado = evento.detail.value;
    this.carregarRanking();
  }
}